import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addAttendanceData,
  deleteAttendance,
  deleteAttendanceFailure,
  deleteAttendanceSuccess,
  getAttendanceList,
  getAttendanceListFailure,
  getAttendanceListSuccess,
  getDailyAttendance,
  getDailyAttendanceFailure,
  getDailyAttendanceSuccess,
  getEmployeeAttendanceLog,
  getEmployeeAttendanceLogFailure,
  getEmployeeAttendanceLogSuccess,
  getEmployeeAttendanceSummary,
  getEmployeeAttendanceSummaryFailure,
  getEmployeeAttendanceSummarySuccess,
  getEmployeeDailyAttendanceReportList,
  getEmployeeDailyAttendanceReportListFailure,
  getEmployeeDailyAttendanceReportListSuccess,
  updateEmployeeAttendance,
} from "./attendanceSlice";

function* onGetAttendance({
  payload: { page = 1, limit = 10, leaveType = "", status = "Pending" },
}) {
  try {
    const url = `/employee-panel/leave-application/data/?limit=${limit}&pageNo=${page}&leaveType=${leaveType}&status=${status}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getAttendanceListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get salary attendance failed";
    yield put(getAttendanceListFailure({ message }));
  }
}

function* onAddAttendance({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, dayOff, handleSubmitting },
  },
}) {
  try {
    const url = dayOff
      ? `/employee-panel/attendance/day-off`
      : `/employee-panel/attendance/attendance-create`;
    const response = yield call(dayOff ? patch : post, url, data);

    if (response) {
      toaster(
        "success",
        `Attendance ${dayOff ? "Day Off" : "Added"} Successfully`
      );
      handleSubmitting(true);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${dayOff ? "Day Off" : "Add"} Attendance Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetDailyAttendance() {
  try {
    const url = `/employee-panel/attendance/chcek-daily-attandance`;
    const response = yield call(get, url);
    if (response) {
      yield put(getDailyAttendanceSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single salary attendance failed";
    toaster("error", message);
    yield put(getDailyAttendanceFailure({ message }));
  }
}

function* onDeleteAttendance({ payload: { id, successCallBack } }) {
  try {
    const url = `/employee-panel/leave-application/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteAttendanceSuccess(response));
      toaster("success", "Attendance Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete salary attendance failed";
    toaster("error", message);
    yield put(deleteAttendanceFailure({ message }));
  }
}

function* onGetEmployeeAttendanceLog({
  payload: { fromDate, toDate, employeeId },
}) {
  try {
    const url = `/employee-panel/attendance/subordinate-attendance-log-report?employeeId=${employeeId}&fromDate=${fromDate}&toDate=${toDate}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getEmployeeAttendanceLogSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get attendance log failed";
    yield put(getEmployeeAttendanceLogFailure({ message }));
  }
}

function* onGetAttendanceSummary({
  payload: { fromDate, toDate, employeeId },
}) {
  try {
    const url = `/employee-panel/attendance/subordinate-date-to-date-attendance-summary?employeeId=${employeeId}&fromDate=${fromDate}&toDate=${toDate}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getEmployeeAttendanceSummarySuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get attendance summary failed";
    yield put(getEmployeeAttendanceSummaryFailure({ message }));
  }
}

function* onGetDailyAttendanceReport({
  payload: { date, employeeId, status },
}) {
  try {
    const url = `/employee-panel/attendance/subordinate-daily-attendance-report?employeeId=${employeeId}&todayDate=${date}&status=${status}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getEmployeeDailyAttendanceReportListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get daily attendance report failed";
    yield put(getEmployeeDailyAttendanceReportListFailure({ message }));
  }
}

function* onUpdateEmployeeAttendance({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = `/employee-panel/attendance/update-attendance-absent-to-present/${id}`;
    const response = yield call(patch, url, data);

    if (response) {
      toaster("success", `Attendance update successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Attendance update failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

export function* attendanceSaga() {
  yield takeEvery(getAttendanceList.type, onGetAttendance);
  yield takeEvery(addAttendanceData.type, onAddAttendance);
  yield takeEvery(getDailyAttendance.type, onGetDailyAttendance);
  yield takeEvery(deleteAttendance.type, onDeleteAttendance);
  yield takeEvery(getEmployeeAttendanceLog.type, onGetEmployeeAttendanceLog);
  yield takeEvery(getEmployeeAttendanceSummary.type, onGetAttendanceSummary);
  yield takeEvery(
    getEmployeeDailyAttendanceReportList.type,
    onGetDailyAttendanceReport
  );
  yield takeEvery(updateEmployeeAttendance.type, onUpdateEmployeeAttendance);
}
