import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addKpiData,
  deleteKpi,
  deleteKpiFailure,
  deleteKpiSuccess,
  getKpiList,
  getKpiListFailure,
  getKpiListSucces,
  getSingleKpi,
  getSingleKpiFailure,
  getSingleKpiSuccess,
} from "./KpiSlice";

function* onGetKpi({ payload: { page = 1, limit } }) {
  try {
    const url = `/kpi/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    // console.log("responseeee", response);
    if (response) {
      yield put(getKpiListSucces(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get KPI Failed";
    yield put(getKpiListFailure({ message }));
  }
}

function* onAddKpi({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/kpi/${id}` : `/kpi`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Kpi ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      yield put(getKpiList({ page: 1, limit: 10 }));
      toggle();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `${id ? "Update" : "Add"} KPI Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleKpi({ payload: { id } }) {
  try {
    const url = `/kpi/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleKpiSuccess(response?.bank));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get single KPI failed";
    toaster("error", message);
    yield put(getSingleKpiFailure({ message }));
  }
}

function* onDeleteKpi({
  payload: { id, setSelectedUser, setCurrentPage, setHandleList, toggle },
}) {
  try {
    const url = `/kpi/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteKpiSuccess(response));
      toaster("success", "KPI deleted successfully");
      setSelectedUser(null);
      setCurrentPage(1);
      setHandleList(10);
      toggle(true);
      // yield put(getKpiList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete KPI failed";
    toaster("error", message);
    yield put(deleteKpiFailure({ message }));
  }
}

export function* kpiSaga() {
  yield takeEvery(getKpiList.type, onGetKpi);
  yield takeEvery(addKpiData.type, onAddKpi);
  yield takeEvery(getSingleKpi.type, onGetSingleKpi);
  yield takeEvery(deleteKpi.type, onDeleteKpi);
}
