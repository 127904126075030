import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addDepartmentData,
  deleteDepartment,
  deleteDepartmentFailure,
  deleteDepartmentSuccess,
  getDepartmentList,
  getDepartmentListFailure,
  getDepartmentListSuccess,
  getSingleDepartment,
  getSingleDepartmentFailure,
  getSingleDepartmentSuccess,
} from "./DepartmentSlice";

function* onGetDepartment({ payload: { page = 1, limit } }) {
  try {
    const url = `/department/data/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getDepartmentListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Department Failed";
    yield put(getDepartmentListFailure({ message }));
  }
}

function* onAddDepartment({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/department/${id}` : `/department`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Department ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      yield put(getDepartmentList({ page: 1, limit: 10 }));
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Department Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleDepartment({ payload: { id } }) {
  try {
    const url = `/department/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleDepartmentSuccess(response?.department));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single department failed";
    toaster("error", message);
    yield put(getSingleDepartmentFailure({ message }));
  }
}

function* onDeleteDepartment({
  payload: { id, setSelectedUser, setCurrentPage, setHandleList, toggle },
}) {
  try {
    const url = `/department/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteDepartmentSuccess(response));
      toaster("success", "Department Deleted successfully");
      setSelectedUser(null);
      setCurrentPage(1);
      setHandleList(10);
      toggle(true);
      // yield put(getDepartmentList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete department failed";
    toaster("error", message);
    yield put(deleteDepartmentFailure({ message }));
  }
}

export function* departmentSaga() {
  yield takeEvery(getDepartmentList.type, onGetDepartment);
  yield takeEvery(addDepartmentData.type, onAddDepartment);
  yield takeEvery(getSingleDepartment.type, onGetSingleDepartment);
  yield takeEvery(deleteDepartment.type, onDeleteDepartment);
}
