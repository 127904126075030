import { createSlice } from "@reduxjs/toolkit";
import { fixedNumber } from "../../../helpers/custom/time";
const month = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

const initialState = {
  recruitmentStageLoading: false,
  recruitmentStage: {},
  typeOfEmployeeLoading: false,
  typeOfEmployee: {},
  stuffTurnOverData: [],
  staffTurnOverLoading: false,
  requestLoading: false,
  request: {},
  topBannerLoading: false,
  topBanner: {},
  error: "",
};

const adminDashboardSlice = createSlice({
  name: "adminDashboard",
  initialState,
  reducers: {
    getRecruitmentStage: (state, action) => {
      state.recruitmentStageLoading = true;
    },
    getRecruitmentStageSuccess: (state, action) => {
      state.recruitmentStageLoading = false;
      state.recruitmentStage = action.payload;
    },
    getRecruitmentStageFail: (state, action) => {
      state.recruitmentStageLoading = false;
      state.error = action.payload?.message;
    },
    getTypeOfEmployee: (state, action) => {
      state.typeOfEmployeeLoading = true;
    },
    getTypeOfEmployeeSuccess: (state, action) => {
      state.typeOfEmployeeLoading = false;
      state.typeOfEmployee = action.payload;
    },
    getTypeOfEmployeeFail: (state, action) => {
      state.typeOfEmployeeLoading = false;
      state.error = action.payload?.message;
    },
    getStuffTurnOver: (state, action) => {
      state.staffTurnOverLoading = true;
    },
    getStuffTurnOverSuccess: (state, { payload }) => {
      let newData = [];
      payload.forEach((act, idx) => {
        const arr = newData?.length ? [...newData] : [...month];
        arr[act?._id - 1] = fixedNumber(act?.count * 1, 2);
        newData = arr;
      });
      state.staffTurnOverLoading = false;
      state.stuffTurnOverData = [
        { name: "Staff Turnover", data: payload?.length ? newData : month },
      ];
    },
    getStuffTurnOverFail: (state, action) => {
      state.staffTurnOverLoading = false;
      state.error = action.payload?.message;
    },
    getRequest: (state, action) => {
      state.requestLoading = true;
    },
    getRequestSuccess: (state, action) => {
      state.requestLoading = false;
      state.request = action.payload;
    },
    getRequestFail: (state, action) => {
      state.requestLoading = false;
      state.error = action.payload?.message;
    },
    getTopBanner: (state, action) => {
      state.topBannerLoading = true;
    },
    getTopBannerSuccess: (state, action) => {
      state.topBannerLoading = false;
      state.topBanner = action.payload;
    },
    getTopBannerFail: (state, action) => {
      state.topBannerLoading = false;
      state.error = action.payload?.message;
    },
  },
});

export const {
  getRecruitmentStage,
  getRecruitmentStageSuccess,
  getRecruitmentStageFail,
  getTypeOfEmployee,
  getTypeOfEmployeeSuccess,
  getTypeOfEmployeeFail,
  getStuffTurnOver,
  getStuffTurnOverSuccess,
  getStuffTurnOverFail,
  getRequest,
  getRequestSuccess,
  getRequestFail,
  getTopBanner,
  getTopBannerSuccess,
  getTopBannerFail,
} = adminDashboardSlice.actions;

export default adminDashboardSlice.reducer;
