import { all, fork } from "redux-saga/effects";
import { adminDashboardSaga } from "./Admin/AdminDashboard/saga";
import { dailyAttendanceReportSaga } from "./Admin/Attendance/saga";
import { categoryItemSaga } from "./Admin/CategoryItem/saga";
import { corporateQuestionSaga } from "./Admin/CorporateQuestions/saga";
import { HolidaysSaga } from "./Admin/Holidays/saga";
import { adminIncrementSaga } from "./Admin/Increment/saga";
import { itemSaga } from "./Admin/Item/saga";
import { levelSaga } from "./Admin/Level/saga";
import { passageSaga } from "./Admin/Passage/saga";
import { performanceSaga } from "./Admin/Performance/saga";
import { adminPromotionSaga } from "./Admin/Promotion/saga";
import { questionSaga } from "./Admin/Questions/saga";
import { serviceSaga } from "./Admin/Service/saga";
import { taxSaga } from "./Admin/Tax/saga";
import { corporateExamSaga } from "./ApplicantPanel/CorporateExam/saga";
import { applicantSaga } from "./ApplicantPanel/Dashboard/saga";
import { applicantProfileSaga } from "./ApplicantPanel/Profile/saga";
import { assessmentSaga } from "./Assessment/saga";
import { loginSaga } from "./Auth/Login/saga";
import { bankSaga } from "./Bank/saga";
import { careerSaga } from "./Career/saga";
import { concernCompanySaga } from "./ConcernCompany/saga";
import { departmentSaga } from "./Department/saga";
import { designationSaga } from "./Designation/saga";
import { employeeSaga } from "./Employee/saga";
import { employeeAssessmentSaga } from "./EmployeePanel/Assessment/saga";
import { attendanceSaga } from "./EmployeePanel/Attendance/saga";
import { performanceRattingSaga } from "./EmployeePanel/Increment/performanceSaga";
import { incrementSaga } from "./EmployeePanel/Increment/saga";
import { salaryIncrementSaga } from "./EmployeePanel/Increment/salaryIncrementSaga";
import { intervieweeSaga } from "./EmployeePanel/Interviewee/saga";
import { leaveSaga } from "./EmployeePanel/Leave/saga";
import { kpiSelectionSaga } from "./EmployeePanel/Performance/kpiSelectionSaga";
import { employeePromotionSaga } from "./EmployeePanel/Promotion/saga";
import { userProfileSaga } from "./EmployeePanel/UserProfile/saga";
import { adminIntervieweeSaga } from "./Interviewee/saga";
import { jobPostSaga } from "./JobPost/saga";
import { kpiSaga } from "./Kpi/saga";
import LayoutSaga from "./Layout/saga";
import { LeaveApplicationSaga } from "./LeaveApplication/saga";
import { LeaveTypeSaga } from "./LeaveType/saga";
import { payrollSaga } from "./Payroll/saga";
import { salarySaga } from "./Payroll/salarySaga";
import { userSaga } from "./User/saga";

export default function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(LayoutSaga),
    fork(userSaga),
    fork(careerSaga),
    fork(concernCompanySaga),
    fork(departmentSaga),
    fork(designationSaga),
    fork(bankSaga),
    fork(employeeSaga),
    fork(LeaveTypeSaga),
    fork(HolidaysSaga),
    fork(LeaveApplicationSaga),
    fork(jobPostSaga),
    fork(questionSaga),
    fork(assessmentSaga),
    fork(applicantSaga),
    fork(intervieweeSaga),
    fork(adminIntervieweeSaga),
    fork(kpiSaga),
    fork(employeeAssessmentSaga),
    fork(levelSaga),
    fork(serviceSaga),
    fork(passageSaga),
    fork(corporateQuestionSaga),
    fork(corporateExamSaga),
    fork(categoryItemSaga),
    fork(itemSaga),
    fork(incrementSaga),
    fork(performanceSaga),
    fork(performanceRattingSaga),
    fork(kpiSelectionSaga),
    fork(attendanceSaga),
    fork(dailyAttendanceReportSaga),
    fork(employeePromotionSaga),
    fork(adminPromotionSaga),
    fork(taxSaga),
    fork(applicantProfileSaga),
    fork(payrollSaga),
    fork(salaryIncrementSaga),
    fork(adminIncrementSaga),
    fork(leaveSaga),
    fork(userProfileSaga),
    fork(salarySaga),
    fork(adminDashboardSaga),
  ]);
}
