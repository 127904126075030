import { call, delay, put, takeEvery } from "redux-saga/effects";
import { get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import { urlToText } from "../../../helpers/custom/capitalize";
import {
  getAllExamQuestions,
  getAllExamQuestionsFailure,
  getAllExamQuestionsSuccess,
  getAptitudeExamQuestions,
  getExamSubmitCount,
  getExamSubmitCountFailure,
  getExamSubmitCountSuccess,
  getWritingAnswer,
  getWritingAnswerFailure,
  getWritingAnswerSuccess,
  reRenderData,
  resetAll,
  resubmitExamData,
  storeCorporateExamQuestion,
  submitAnsOneByOne,
  submitAnsOneByOneFailure,
  submitAnsOneByOneSuccess,
  submitAptitudeAnsOneByOne,
  submitExamData,
  submitExamDataFailure,
  submitExamDataSuccess,
  submitWritingAnswer,
} from "./CorporateExamSlice.js";

function* onGetAllExamsQuestions({
  payload: {
    type,
    serviceId,
    setQuantity,
    levelId,
    assessmentAssignId,
    prevQuestionData,
    history,
  },
}) {
  try {
    const getAllQuestion = `/admin/${type}/get-random-question/${levelId}/${setQuantity}`,
      submitUrl = `/admin/${type}/exam-already-given/${assessmentAssignId}`;
    /// for checking result is already submitted or not
    const checkSubmit = yield call(get, submitUrl);
    if (checkSubmit?.getData) {
      toaster("success", "Answer has already submitted");
      yield put(
        getAllExamQuestionsSuccess({
          questions: [],
          startExam: {},
          type,
          isDone: checkSubmit?.getData,
        })
      );
      yield delay(1000);
      return history.goBack();
    }
    /// for submit answer after page reload
    if (prevQuestionData) {
      const { type, examId, answerId, time, submissionReason, examStatus } =
        prevQuestionData;
      yield put(
        storeCorporateExamQuestion({ name: "isRouteChange", data: false })
      );
      const url = `/admin/${type}/submit-answer/${examId}/${answerId}?timeTaken=${time}&examStatus=${examStatus}&submissionReason=${submissionReason}`;
      const response = yield call(patch, url, {});
      if (response) {
        history.goBack();
        localStorage.removeItem("hrx-xam");
        toaster("success", "Exam has submitted successfully");
        return;
      }
    }
    /// get all questions
    const response = yield call(get, getAllQuestion);
    const { getData } = response;
    // if (getData?.length === 0) {
    //   yield put(
    //     storeCorporateExamQuestion({ name: "isRouteChange", data: false })
    //   );
    //   toaster("warning", "Sorry! Questions are not available");
    //   history.goBack();
    //   return;
    // }
    const ids = getData?.map((el) => el?._id);
    let data = {};
    switch (type) {
      case "reading":
        data = { readingPassageIds: ids };
        break;
      case "listening":
        data = { listeningAudioIds: ids };
        break;
      case "writing":
        data = { writingQuestionIds: ids };
        break;
      default:
        break;
    }
    /// start exam api
    const startUrl = `/admin/${type}/exam-start/${assessmentAssignId}`;
    // console.log(`🦄 ~ file: saga.js:31 ~ data`, data, startUrl);
    const startExam = yield call(post, startUrl, data);
    toaster("success", startExam?.message);
    const url = `/applicant-panel/assessment/${assessmentAssignId}`;
    const assessmentData = yield call(get, url);
    // console.log(`assessmentData`, assessmentData);
    yield put(
      getAllExamQuestionsSuccess({
        questions: getData,
        startExam: startExam?.getData,
        assessmentData: assessmentData?.assessment,
        // startExam: {},
        type,
        isDone: checkSubmit?.getData,
      })
    );
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get exam question failed";
    toaster("error", message);
    yield put(getAllExamQuestionsFailure({ message }));
  }
}

function* onSubmitAnsOneByOne({
  payload: {
    data,
    options: {
      type,
      questionGroupId,
      questionId,
      answerId,
      examStartId,
      assessmentId,
    },
  },
}) {
  try {
    const url =
      type === "writing"
        ? `/admin/writing/answer-submit-one-by-one/${examStartId}/${assessmentId}/${questionId}`
        : `/admin/${type}/answer-submit-one-by-one/${questionGroupId}/${questionId}/${answerId}`;
    const response = yield call(patch, url, data);
    yield put(submitAnsOneByOneSuccess(response?.getData));
  } catch (error) {
    const message = error?.response?.data?.message || "Submit ans failed";
    toaster("error", message);
    yield put(submitAnsOneByOneFailure({ message }));
  }
}

function* onSubmitExamData({
  payload: {
    data = {},
    options: {
      answerId,
      type,
      history,
      time,
      examStatus,
      submissionReason,
      examStartId,
      serviceName,
    },
  },
}) {
  try {
    let url = "";
    switch (type) {
      case "writing":
        url = `/admin/${type}/exam-submit/${examStartId}?timeTaken=${time}&examStatus=${examStatus}&submissionReason=${submissionReason}`;
        break;
      case "aptitude":
        url = `/admin/gmat/submit-answer/${examStartId}/${answerId}?examType=${urlToText(
          serviceName
        )}&timeTaken=${time}&examStatus=${examStatus}&submissionReason=${submissionReason}`;
        break;
      default:
        url = `/admin/${type}/submit-answer/${examStartId}/${answerId}?timeTaken=${time}&examStatus=${examStatus}&submissionReason=${submissionReason}`;
        break;
    }
    const response = yield call(patch, url, data);
    toaster("success", "Exam has submitted successfully");
    history.goBack();
    localStorage.removeItem("hrx-xam");
    yield put(submitExamDataSuccess(response?.getData));
    yield delay(1000);
    yield put(resetAll());
  } catch (error) {
    const message = error?.response?.data?.message || "Submit exam failed";
    toaster("error", message);
    yield put(submitExamDataFailure({ message }));
  }
}

function* onSubmitAptitudeAnsOneByOne({
  payload: {
    data = {},
    options: { answerId, questionId },
  },
}) {
  try {
    const url = `/admin/gmat/answer-submit-one-by-one/${questionId}/${answerId}`;
    const response = yield call(patch, url, data);
    yield put(submitAnsOneByOneSuccess(response?.getData));
  } catch (error) {
    const message = error?.response?.data?.message || "Submit ans failed";
    toaster("error", message);
    yield put(submitAnsOneByOneFailure({ message }));
  }
}

function* onResubmitExamData({ payload: { prevQuestionData, history } }) {
  try {
    yield put(
      storeCorporateExamQuestion({ name: "isRouteChange", data: false })
    );
    const {
      type,
      examId,
      answerId,
      time,
      submissionReason,
      examStatus,
      examStartId,
    } = prevQuestionData;
    const submitUrl = `/admin/${type}/exam-already-given/${examId}`;
    const checkSubmit = yield call(get, submitUrl);
    // console.log(`checkSubmit`, checkSubmit?.getData);
    if (checkSubmit?.getData) return;
    const url =
      type === "writing"
        ? `/admin/${type}/exam-submit/${examStartId}?timeTaken=${time}&examStatus=${examStatus}&submissionReason=${submissionReason}`
        : `/admin/${type}/submit-answer/${examStartId}/${answerId}?timeTaken=${time}&examStatus=${examStatus}&submissionReason=${submissionReason}`;
    const response = yield call(patch, url, {});
    if (response) {
      toaster("success", "Exam has submitted successfully");
      localStorage.removeItem("hrx-xam");
      yield put(resetAll());
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Submit exam failed";
    toaster("error", message);
    yield put(submitExamDataFailure({ message }));
  }
}

function* onGetAptitudeExamsQuestions({
  payload: {
    type,
    serviceId,
    assessmentAssignId,
    data: body = {},
    prevQuestionData,
    history,
    serviceName,
  },
}) {
  try {
    const getAllQuestion = `/admin/gmat/get-random-question/${serviceId}`;
    const submitUrl = `/admin/gmat/exam-already-given/${assessmentAssignId}/${serviceId}`;
    /// for checking result is already submitted or not
    const checkSubmit = yield call(get, submitUrl);
    if (checkSubmit?.getData) {
      toaster("success", "Answer has already submitted");
      yield put(
        getAllExamQuestionsSuccess({
          questions: [],
          startExam: {},
          type,
          isDone: checkSubmit?.getData,
        })
      );
      yield delay(1000);
      return history.goBack();
    }
    /// for submit answer after page reload
    if (prevQuestionData) {
      const { type, examId, answerId, time, submissionReason, examStatus } =
        prevQuestionData;
      yield put(
        storeCorporateExamQuestion({ name: "isRouteChange", data: false })
      );
      const url = `/admin/gmat/submit-answer/${examId}/${answerId}?timeTaken=${time}&examStatus=${examStatus}&submissionReason=${submissionReason}?examType=${urlToText(
        type
      )}`;
      const response = yield call(patch, url, {});
      if (response) {
        history.goBack();
        localStorage.removeItem("hrx-xam");
        toaster("success", "Exam has submitted successfully");
        return;
      }
    }
    /// get all questions
    const response = yield call(
      type === "aptitude" ? post : get,
      getAllQuestion,
      body
    );
    const { getData } = response;
    // if (getData?.length === 0) {
    //   yield put(
    //     storeCorporateExamQuestion({ name: "isRouteChange", data: false })
    //   );
    //   toaster("warning", "Sorry! Questions are not available");
    //   history.goBack();
    //   return;
    // }
    const ids = getData?.map((el) => el?._id);
    const data = { gmatQuestionIds: ids };
    /// start exam api
    const startUrl = `/admin/gmat/exam-start/${assessmentAssignId}/${serviceId}`;
    const startExam = yield call(post, startUrl, data);
    toaster("success", startExam?.message);
    const url = `/applicant-panel/assessment/${assessmentAssignId}`;
    const assessmentData = yield call(get, url);
    // console.log(`🦄 ~ file: saga.js:303 ~ assessmentData`, assessmentData);
    yield put(
      getAllExamQuestionsSuccess({
        questions: getData,
        startExam: startExam?.getData,
        // startExam: {},
        assessmentData: assessmentData?.assessment,
        type,
        isDone: checkSubmit?.getData,
      })
    );
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get exam question failed";
    toaster("error", message);
    yield put(getAllExamQuestionsFailure({ message }));
  }
}

function* onGetWritingAnswer({ payload: { assessmentId, applicantId } }) {
  try {
    const url = `/admin/writing/reviewer/get-answer/${assessmentId}/${applicantId}`;
    const response = yield call(get, url);
    yield put(getWritingAnswerSuccess(response?.getData));
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get writing answer failed";
    toaster("error", message);
    yield put(getWritingAnswerFailure({ message }));
  }
}

function* onSubmitWritingAnswer({
  payload: {
    data,
    options: {
      assessmentId,
      applicantId,
      answerId,
      questionId,
      setSubmitting,
      resetForm,
      isBack,
      history,
    },
  },
}) {
  try {
    const url = `/admin/writing/reviewer/submit-mark/${answerId}/${questionId}/${applicantId}/${assessmentId}`;
    const response = yield call(patch, url, data);
    if (response) {
      yield setSubmitting(false);
      yield resetForm();
      toaster("success", response?.message);
      if (isBack) {
        history.goBack();
      } else {
        yield put(reRenderData());
      }
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get writing answer failed";
    toaster("error", message);
    yield put(getWritingAnswerFailure({ message }));
    yield setSubmitting(false);
  }
}
// /admin/writing/reviewer/get-answer/63b2c08adc19ac000860f06b/63903ed100ffe700081c4014

function* onGetExamSubmitCount({ payload: { assessmentId } }) {
  try {
    const url = `/career-applicant-auth/applicant-exam-status/${assessmentId}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getExamSubmitCountSuccess(response?.getData));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get exam count failed";
    toaster("error", message);
    yield put(getExamSubmitCountFailure({ message }));
  }
}

export function* corporateExamSaga() {
  yield takeEvery(getAllExamQuestions.type, onGetAllExamsQuestions);
  yield takeEvery(getAptitudeExamQuestions.type, onGetAptitudeExamsQuestions);
  yield takeEvery(submitAnsOneByOne.type, onSubmitAnsOneByOne);
  yield takeEvery(submitAptitudeAnsOneByOne.type, onSubmitAptitudeAnsOneByOne);
  yield takeEvery(submitExamData.type, onSubmitExamData);
  yield takeEvery(resubmitExamData.type, onResubmitExamData);
  yield takeEvery(getWritingAnswer.type, onGetWritingAnswer);
  yield takeEvery(submitWritingAnswer.type, onSubmitWritingAnswer);
  yield takeEvery(getExamSubmitCount.type, onGetExamSubmitCount);
}
