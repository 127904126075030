import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  attendanceList: [],
  loading: false,
  error: "",
  checkAttendance: {},
  checkAttendanceData: {},
  dailyAttendanceLogList: {},
  attendanceSummaryList: {},
  dailyAttendanceReportList: {},
  adding: false,
};

const attendanceSlice = createSlice({
  name: "attendance",
  initialState: initialState,
  reducers: {
    getAttendanceList: (state, action) => {
      state.loading = true;
    },
    getAttendanceListSuccess: (state, action) => {
      state.attendanceList = action.payload;
      state.loading = false;
    },
    getAttendanceListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addAttendanceData: (state, action) => {},
    getDailyAttendance: (state, action) => {
      state.loading = true;
    },
    getDailyAttendanceSuccess: (state, action) => {
      state.checkAttendance = action.payload?.attendance;
      state.checkAttendanceData = action.payload;
      state.loading = false;
    },
    getDailyAttendanceFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteAttendance: (state, action) => {
      state.adding = true;
    },
    deleteAttendanceSuccess: (state, action) => {
      state.adding = false;
    },
    deleteAttendanceFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
    getEmployeeAttendanceLog: (state, action) => {
      state.loading = true;
    },
    getEmployeeAttendanceLogSuccess: (state, action) => {
      state.dailyAttendanceLogList = action.payload;
      state.loading = false;
    },
    getEmployeeAttendanceLogFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getEmployeeAttendanceSummary: (state, action) => {
      state.loading = true;
    },
    getEmployeeAttendanceSummarySuccess: (state, action) => {
      state.attendanceSummaryList = action.payload;
      state.loading = false;
    },
    getEmployeeAttendanceSummaryFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getEmployeeDailyAttendanceReportList: (state, action) => {
      state.loading = true;
    },
    getEmployeeDailyAttendanceReportListSuccess: (state, action) => {
      state.dailyAttendanceReportList = action.payload;
      state.loading = false;
    },
    getEmployeeDailyAttendanceReportListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    updateEmployeeAttendance: (state, action) => {},
  },
});

export const {
  getAttendanceList,
  getAttendanceListSuccess,
  getAttendanceListFailure,
  addAttendanceData,
  getDailyAttendance,
  getDailyAttendanceSuccess,
  getDailyAttendanceFailure,
  deleteAttendance,
  deleteAttendanceSuccess,
  deleteAttendanceFailure,
  getEmployeeAttendanceLog,
  getEmployeeAttendanceLogSuccess,
  getEmployeeAttendanceLogFailure,
  getEmployeeAttendanceSummary,
  getEmployeeAttendanceSummarySuccess,
  getEmployeeAttendanceSummaryFailure,
  getEmployeeDailyAttendanceReportList,
  getEmployeeDailyAttendanceReportListSuccess,
  getEmployeeDailyAttendanceReportListFailure,
  updateEmployeeAttendance,
} = attendanceSlice.actions;

export default attendanceSlice.reducer;
