import { call, put, takeEvery } from "redux-saga/effects";
import { get } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  employeeVacation,
  employeeVacationFailure,
  employeeVacationSuccess,
  getSingleUser,
  getSingleUserFailure,
  getSingleUserSuccess,
} from "./userProfileSlice";

function* onGetSingleUser({ payload: { id } }) {
  try {
    const url = `/employee-panel/profile/${id}`;
    const response = yield call(get, url);
    // console.log(response);
    if (response) {
      yield put(getSingleUserSuccess(response?.myProfile));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get User failed";
    yield put(getSingleUserFailure({ message }));
  }
}

function* onEmployeeVacation({ payload: { id, year = "" } }) {
  try {
    const currentyear = new Date().getFullYear();
    const vacationUrl = `/employee-panel/leave-application/leave-summary?employeeId=${id}&year=${
      year || currentyear
    }`;
    const vacation = yield call(get, vacationUrl);
    if (vacation) {
      yield put(employeeVacationSuccess(vacation));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single employee vacation failed";
    toaster("error", message);
    yield put(employeeVacationFailure({ message }));
  }
}

export function* userProfileSaga() {
  yield takeEvery(getSingleUser.type, onGetSingleUser);
  yield takeEvery(employeeVacation.type, onEmployeeVacation);
}
