import adminDashboardSlice from "./Admin/AdminDashboard/adminDashboardSlice";
import dailyAttendanceReportSlice from "./Admin/Attendance/attendanceSlice";
import CategoryItemSlice from "./Admin/CategoryItem/CategoryItemSlice";
import CorporateQuestionSlice from "./Admin/CorporateQuestions/CorporateQuestionSlice";
import HolidaysSlice from "./Admin/Holidays/HolidaysSlice";
import adminIncrementSlice from "./Admin/Increment/adminIncrementSlice";
import ItemSlice from "./Admin/Item/ItemSlice";
import LevelSlice from "./Admin/Level/LevelSlice";
import PassageSlice from "./Admin/Passage/PassageSlice";
import performanceSlice from "./Admin/Performance/performanceSlice";
import adminPromotionSlice from "./Admin/Promotion/PromotionSlice";
import QuestionSlice from "./Admin/Questions/QuestionSlice";
import ServiceSlice from "./Admin/Service/ServiceSlice";
import TaxSlice from "./Admin/Tax/TaxSlice";
import CorporateExamSlice from "./ApplicantPanel/CorporateExam/CorporateExamSlice";
import ApplicantSlice from "./ApplicantPanel/Dashboard/ApplicantSlice";
import ApplicantProfileSlice from "./ApplicantPanel/Profile/ApplicantProfileSlice";
import AssessmentSlice from "./Assessment/AssessmentSlice";
import loginSlice from "./Auth/Login/loginSlice";
import BankSlice from "./Bank/BankSlice";
import CareerSlice from "./Career/CareerSlice";
import ConcernCompanySlice from "./ConcernCompany/ConcernCompanySlice";
import DepartmentSlice from "./Department/DepartmentSlice";
import DesignationSlice from "./Designation/DesignationSlice";
import EmployeeSlice from "./Employee/EmployeeSlice";
import EmployeeAssessmentSlice from "./EmployeePanel/Assessment/AssessmentSlice";
import attendanceSlice from "./EmployeePanel/Attendance/attendanceSlice";
import IncrementSlice from "./EmployeePanel/Increment/IncrementSlice";
import performanceRattingSlice from "./EmployeePanel/Increment/PerformanceRatingSlice";
import salaryIncrementSlice from "./EmployeePanel/Increment/salaryIncrementSlice";
import IntervieweeSlice from "./EmployeePanel/Interviewee/IntervieweeSlice";
import leaveSlice from "./EmployeePanel/Leave/leaveSlice";
import kpiSelectionSlice from "./EmployeePanel/Performance/kpiSelectionSlice";
import employeePromotionSlice from "./EmployeePanel/Promotion/PromotionSlice";
import userProfileSlice from "./EmployeePanel/UserProfile/userProfileSlice";
import filterSlice from "./Filter/filterSlice";
import AdminIntervieweeSlice from "./Interviewee/IntervieweeSlice";
import JobPostSlice from "./JobPost/JobPostSlice";
import KpiSlice from "./Kpi/KpiSlice";
import LayoutSlice from "./Layout/LayoutSlice";
import LeaveApplicationSlice from "./LeaveApplication/LeaveApplicationSlice";
import LeaveTypeSlice from "./LeaveType/LeaveTypeSlice";
import payrollSlice from "./Payroll/payrollSlice";
import salarySlice from "./Payroll/salarySlice";
import UserSlice from "./User/UserSlice";

export const rootReducers = {
  login: loginSlice,
  layout: LayoutSlice,
  user: UserSlice,
  concernCompany: ConcernCompanySlice,
  department: DepartmentSlice,
  designation: DesignationSlice,
  bank: BankSlice,
  career: CareerSlice,
  employee: EmployeeSlice,
  leaveType: LeaveTypeSlice,
  holidays: HolidaysSlice,
  leaveApplication: LeaveApplicationSlice,
  jobPost: JobPostSlice,
  question: QuestionSlice,
  assessment: AssessmentSlice,
  applicant: ApplicantSlice,
  applicantProfile: ApplicantProfileSlice,
  interviewee: IntervieweeSlice,
  adminInterviewee: AdminIntervieweeSlice,
  kpiData: KpiSlice,
  employeeAssessment: EmployeeAssessmentSlice,
  service: ServiceSlice,
  level: LevelSlice,
  passage: PassageSlice,
  corporateQuestion: CorporateQuestionSlice,
  corporateExam: CorporateExamSlice,
  item: ItemSlice,
  categoryItem: CategoryItemSlice,
  increment: IncrementSlice,
  performance: performanceSlice,
  performanceRatting: performanceRattingSlice,
  kpiSelection: kpiSelectionSlice,
  attendance: attendanceSlice,
  dailyAttendanceReport: dailyAttendanceReportSlice,
  employeePromotion: employeePromotionSlice,
  adminPromotion: adminPromotionSlice,
  tax: TaxSlice,
  filter: filterSlice,
  payroll: payrollSlice,
  salaryIncrement: salaryIncrementSlice,
  adminIncrement: adminIncrementSlice,
  leave: leaveSlice,
  userProfile: userProfileSlice,
  salary: salarySlice,
  adminDashboard: adminDashboardSlice,
};
