import { call, put, takeEvery } from "redux-saga/effects";
import { get } from "../../../helpers/api_helper";
import {
  getRecruitmentStage,
  getRecruitmentStageFail,
  getRecruitmentStageSuccess,
  getRequest,
  getRequestFail,
  getRequestSuccess,
  getStuffTurnOver,
  getStuffTurnOverFail,
  getStuffTurnOverSuccess,
  getTopBanner,
  getTopBannerFail,
  getTopBannerSuccess,
  getTypeOfEmployee,
  getTypeOfEmployeeFail,
  getTypeOfEmployeeSuccess,
} from "./adminDashboardSlice";

function* onGetRecruitmentStage() {
  try {
    const url = "/dashboard/recruitment-stage";
    const response = yield call(get, url);
    if (response) {
      yield put(getRecruitmentStageSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Recruitment Stage Failed!";
    yield put(getRecruitmentStageFail({ message }));
  }
}

function* onGetTypeOfEmployee() {
  try {
    const url = "/dashboard/type-of-employee";
    const response = yield call(get, url);
    if (response) {
      yield put(getTypeOfEmployeeSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Type of Employee Failed!";
    yield put(getTypeOfEmployeeFail({ message }));
  }
}

function* onGetStuffTurnOver({ payload: { year = "" } }) {
  try {
    const selectedDate = year || new Date().getFullYear();
    const url = `/dashboard/staff-turnover?year=${selectedDate}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getStuffTurnOverSuccess(response?.emplyeeTurnover));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Type of stuff turn over Failed!";
    yield put(getStuffTurnOverFail({ message }));
  }
}

function* onGetRequest({ payload: { year = "" } }) {
  try {
    const url = `/dashboard/request?year=${year}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getRequestSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Request Failed!";
    yield put(getRequestFail({ message }));
  }
}

function* onGetTopBanner() {
  try {
    const url = `/dashboard/top-banner`;
    const response = yield call(get, url);
    if (response) {
      yield put(getTopBannerSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Top Banner Failed!";
    yield put(getTopBannerFail({ message }));
  }
}

export function* adminDashboardSaga() {
  yield takeEvery(getRecruitmentStage.type, onGetRecruitmentStage);
  yield takeEvery(getTypeOfEmployee.type, onGetTypeOfEmployee);
  yield takeEvery(getStuffTurnOver.type, onGetStuffTurnOver);
  yield takeEvery(getRequest.type, onGetRequest);
  yield takeEvery(getTopBanner.type, onGetTopBanner);
}
